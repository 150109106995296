<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getBusinessDocumentRegistry.name"
                    :avatarData="getBusinessDocumentRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                :title="getBusinessDocumentRegistry.name"
                    :items="getBusinessDocumentTable.items"
                    :headers="headers"
                    :length="getBusinessDocumentTable.pages"
                    :page="getBusinessDocumentTable.page"
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @clear-table="clearTable"
                    @sort="setSort"
                    :defaultFields="
                        getBusinessDocumentSearchFields.searchFields
                    "
                    :loading="getBusinessDocumentTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getBusinessDocumentTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('business-document:addingBusinessDocument')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addBusinessDocument()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{
                            $t('business-document:addBusinessDocument')
                        }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Business-document/Table/Columns'
import AEContent from './../../../components/Registries/Business-document/Modal/Content'
import Buttons from './../../../components/Registries/Business-document/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('business-document:index'),
                    value: 'index',
                    width: '8%',
                    showSearch: true,
                },
                {
                    text: this.$t('business-document:createdAt'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: this.$t('business-document:contractor'),
                    value: 'contractor',
                    width: '23%',
                    showSearch: true,
                },
                {
                    text: this.$t('business-document:expirationDate'),
                    value: 'expirationDate',
                    width: '10%',
                },
                {
                    text: this.$t('business-document:title'),
                    value: 'title',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('business-document:status'),
                    value: 'status.name',
                    width: '100px',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getBusinessDocumentRegistry',
            'getBusinessDocumentTable',
            'getBusinessDocumentSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchBusinessDocumentTable', 'fetchUsers']),
        ...mapMutations([
            'clearBusinessDocumentModal',
            'clearBusinessDocumentErrors',
            'setBusinessDocumentTable',
            'clearBusinessDocumentTable',
            'setBusinessDocumentSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            // this.clearCarsErrors()
            this.open = false
        },
        async addBusinessDocument() {
            this.open = true
            this.clearBusinessDocumentErrors()
            this.clearBusinessDocumentModal()
        },
        setSearchFields(searchFields) {
            this.setBusinessDocumentSearch(searchFields)
        },
        setSearch(search) {
            this.setBusinessDocumentTable({ search, page: 1 })
            this.fetchBusinessDocumentTable()
        },
        setPage(page) {
            this.setBusinessDocumentTable({ page })
            this.fetchBusinessDocumentTable()
        },
        setSort(sort) {
            this.setBusinessDocumentTable({ sort })
            this.fetchBusinessDocumentTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearBusinessDocumentTable()
            this.fetchBusinessDocumentTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (
            from.matched[1] &&
            from.matched[1].path !== '/business-document/:id'
        )
            store.commit('clearBusinessDocumentTable')
        store.dispatch('fetchBusinessDocumentTable').then(() => next())
    },
}
</script>
